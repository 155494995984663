body {
    display:flex;
    #root {
	flex-grow: 1;
	display:flex;
	flex-direction: column;
    }
    //background: #438AF7;
    //rgba(67, 138, 247, 1)
}

.center {
    display: flex;
    text-align: center;
    align-items: center;
}

.navigation {
    color: white;
    background-color: "#1E1A55";
    shadow: smooth;
    display: flex;
}

.navigation-brand {
    color: white;
    background-color: "#1E1A55";
    shadow: smooth;
    display: flex;
}

.navigation-menu li {
    color: white;
    background-color: "#1E1A55";
    shadow: smooth;

}

.navigation-menu li {
    color: white;
    background-color: "#1E1A55";
    shadow: smooth;

}
.header-item-active {
    color: "#D5933A";
    background-color: "#1E1A55";
    shadow: smooth;


}

.clickable {
    cursor:pointer
}

.side-menu-hidden {
	display:     "hidden"

}


.side-menu {
	height:150px;
	background: white;
	margin-top: -150px;
	background-image: './media/images/pattern-dots.svg';
	background-size:auto 50%;
	background-position: 20px 20px;
	background-repeat:no-repeat;
	position:relative;
}


.side-menu-active {
	height:150px;
	background:white;
	margin-top: 0px ;
	background-image: './media/images/pattern-dots.svg';
	background-size:auto 50%;
	background-position: 20px 20px;
	background-repeat:no-repeat;
	position:relative;
}


.hamburger-menu {
    width: 24px;
    height: 24px;
    position: relative;
    margin: 0 0 0 10px;
}
  
.hamburger-menu .line {
    width: 100%;
    height: 3px;
    background: #000;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -1.5px;
    transition: all 0.3s;
}
  _
.hamburger-menu .line:nth-child(1) {
    top: 0;
}
  
.hamburger-menu .line:nth-child(2) {
    top: 50%;
}
  
.hamburger-menu .line:nth-child(3) {
    bottom: 0;
}

.footer-box {
    background-color: white;
    padding: 20px;
    position: relative;
}

.investment-box {
    background-color: white;
    padding: 20px;
    position: relative;
    width: 100%;
    height: 80%;
    font-size: 1.2em;
}

.chart-container {
    position: relative;
    height: 100%;
    width: 100%;
}


.pnl {
   padding: 5px;
   text-align: right;
    background-color: white;
}

.pnl-highlight {
   padding: 5px;
   text-align: right;
   background-color: #ECECF0;
}

.th.pnl {
}

.pnl-keys {
   padding: 5px;
   text-align: left;
    background-color: #ECECF0;
}

.pnl-keys-highlight {
   padding: 5px;
   text-align: left;
   background-color: #D1DEF6;
}

.investment-list {
    padding: 24px;
    text-align: left;
    /*display: flex;*/
}

.investment-buttons {
    padding: 2px;
    text-align: center;
    /*display: flex;*/
}

.investment-content {
    //rgba(67, 138, 247, 1)
    background-color:rgb(208, 226, 245);
    //background-color:rgb(220, 250, 216);
    color: #504f4f;
    padding: 12px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    border-color: white;
    border-width: 2px;
}

.investment-content-avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
 }

.leaflet {
  width: 400px;
  height: 400px;
}

//.nowrap{
//    display:inline-block;
//}

.leaflet-container {
  width: 400px;
  height: 400px;
}

.investment-content-author {
    width: 25px;
    height: 50px;
 }

.investment-content-timestamp {
    width: 25px;
    height: 50px;
 }

.investment-content-text {
    color: black;
    margin-left: 2rem;
}

.investment-content-title {
    color: black;
    margin-left: 2rem;
}



.login-nav {
    flex-grow: 1;
    justify: center;
    transition: width 2s;
     py: 4;
     px: 4;
     pr: 8;
     rounded: "t-md";
     color: "gray-600";
     font: "medium";
     background-color: "gray-300";
     cursor: "pointer";
}

.login-nav:hover {
     background-color: gray-100;
}

.login-nav-active {
    flex-grow: 1;
    justify: center;
    transition: width 2s;
     py: 4;
     px: 4;
     pr: 8;
     rounded: "t-md";
     color: "gray-800";
     font: "medium";
     background-color: "white";
     cursor: "pointer";
}
.login-nav-active:hover {
     background-color: white;
}

.login-box {
    transition: "mr-4";
    flex: "shrink-0";
    inline: "flex";
    items: "center";
    justify: "center";
    text:    "sm";
    height: 6;
    width: 6;
    rounded: "full";
    background-color: "gray-200";
    color: "gray";

}

.login-box-active {
    transition: "mr-4";
    flex: "shrink-0";
    inline: "flex";
    items: "center";
    justify: "center";
    text:    "sm";
    height: 6;
    width: 6;
    rounded: "full";
    background-color: "green-300";
    color: "green";
}

.login-box-icon {
    transition: "h-4";
    height: 4;
    width: 4;
    background-color: "gray-400";
}

.login-box-icon-active {
    transition: "h-4";
    height: 4;
    width: 4;
    background-color: "green-600";
}













.chatbox {
    /*flex: 1;*/
    background-color: white;
    padding: 20px;
    position: relative;
    width: 100%;
    height: 80%;
    font-size: 1.2em;
}

.chat-log {
    padding: 24px;
    text-align: left;
    /*display: flex;*/
}

.chat-message {
    background-color:rgb(220, 250, 216);
    color: #504f4f;
    padding: 12px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
}

.chat-message.chatgpt {
    background-color:rgb(255, 236, 236);
    color: #504f4f;
}

.chat-message-avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
 }

.chat-message-avatar.chatgpt {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-image: url("./media/images/avatar-chatgpt.png");
}


.chat-message-text {
    color: black;
    margin-left: 2rem;
}


.chat-message-text.chatgpt {
    background-color:rgb(255, 193, 193);
    color: black;
}

.chat-input-holder {
    padding: 10px;

}

.chat-input-button {
    background-color: #40414f;
    width: 20%;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 1.25em;
    padding: 0px;
    outline: none;
    box-shadow: 0 0 8px 0 rgba(0,0,0, 0.5);
}

.chat-input-textarea {
    background-color: #fff;
    width: 100%;
    border-radius: 5px;
    border: none;
    color: black;
    font-size: 1.25em;
    padding: 12px;
    outline: none;
    box-shadow: 0 0 8px 0 rgba(0,0,0, 0.5);
}



._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}


@media only screen and (max-width: 825px) {
    .brand-logo {
        width: 90%;
    }
}


